import { Alert, Box, Typography } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import { SideMenu } from '../modules/side-menu';
import { FixedWhatsappButton } from '../components/fixed-whatsapp-button';

import { AppHeader } from '../modules/app-header';
import { policy, terms } from '../routes';
import { useIsMobile } from '../helpers/hooks/useIsMobile';

export const Root = () => {
  const isMobile = useIsMobile();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      position="relative"
      minHeight={`${isMobile ? window.innerHeight * 3 : window.innerHeight}px`}
    >
      <SideMenu />
      <Box
        sx={{
          margin: '0 auto',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box>
          <AppHeader />
          <Box sx={{ my: 2 }}>
            <Alert severity="warning">
              <Typography fontWeight="bold" variant="h6">
                Atenção
              </Typography>
              <Typography variant="body1">
                A partir de 02/12/2024, o Copiloto será descontinuado. Lembre-se
                de salvar suas notas anteriores em seu dispositivo.
              </Typography>
            </Alert>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              marginTop: (theme) => ({
                xs: theme.spacing(6),
                md: theme.spacing(8),
              }),
              paddingX: (theme) => theme.spacing(2),
            }}
          >
            <Outlet />
          </Box>
          <Box
            sx={{
              width: '100%',
              flexWrap: { xs: 'wrap', md: 'nowrap' },
              display: 'flex',
              position: 'absolute',
              left: { xs: '0', md: '-10px' },
              bottom: '24px',
              padding: { xs: '0 16px', md: '0' },
              justifyContent: 'space-between',
              alignItems: 'end',
              gap: { xs: '8px', md: '24px' },
              fontSize: '18px',
              color: '#717171',
            }}
          >
            <Box>
              <Typography variant="body1">@ 2024 Carecode</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                marginLeft: 'auto',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '24px',
                  flexWrap: { xs: 'wrap', md: 'nowrap' },
                }}
              >
                <Box>
                  <Link to={terms}>
                    <Typography variant="body1">Termos de uso</Typography>
                  </Link>
                </Box>
                <Box>
                  <Link to={policy}>
                    <Typography variant="body1">
                      Política de privacidade
                    </Typography>
                  </Link>
                </Box>
                <Box>
                  <Typography variant="body1">
                    contato@carecode.com.br
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <FixedWhatsappButton />
    </Box>
  );
};
